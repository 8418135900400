import { defineStore } from 'pinia'
import { reactive, Ref, ref } from 'vue'
interface DragItem {
    id: number | string;
    w: number;
    h: number;
    x: number;
    y: number;
    isActive: boolean;
}
export const useDrags = defineStore('objdrags', () => {
    const drags = reactive<Map<string, DragItem>>(new Map([]))
    const activity = ref(false)
    function dragstop(name, rect: {
        left: number;
        top: number;
    }) {

        const drag = drags.get(name)
        if (!drag) {
            return {
                x: rect.left,
                y: rect.top
            }
        }
        //判断ctrl是否按下，若按下则不吸附
        const key = (window?.event as any)?.ctrlKey
        if (key) {
            return {
                x: rect.left,
                y: rect.top
            }
        }
        const keys = drags.keys();
        const x_arr: any = []
        const y_arr: any = []
        for (const k of keys) {
            if (k === name) {
                continue
            }
            x_arr.push(drags.get(k)?.x || 0)
            y_arr.push(drags.get(k)?.y || 0)
        }


        //是否有x轴可吸附
        const xHave = x_arr?.find(item => {
            if (Math.abs(item - rect.left) <= 10) {
                return true
            }
            return false
        })
        //是否有y轴可吸附
        const yHave = y_arr?.find(item => {
            if (Math.abs(item - rect.top) <= 10) {
                return true
            }
            return false
        })
        return {
            x: xHave || rect.left,
            y: yHave || rect.top
        }

    }
    function getDrags() {
        return drags
    }
    function setDrags(newDtage: Map<string, DragItem>) {

        for (const [key, val] of newDtage) {
            drags.set(key, val)
        }

    }
    return { drags, activity, dragstop, getDrags, setDrags }
})
