import { request as axios } from '@/util/request';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');

interface QuerySendData {
  name?: string;
  receiver?: string;
  receiver_mobile?: number;
  page?: number;
  limit?: number;
}

interface AddSendData {
  name: string;
  address: string;
  receiver: number;
  receiver_mobile: string | number;
}

const API = {
  list: '/boss/factory',
};

function formatWare(remote: any = {}) {
  return {
    current: remote.current || 1,
    limit: remote.limit || 10,
    detail: remote.detail || [],
    total: remote.total || 0,
    last: remote.last || 1,
  };
}


/**
 * 查询工厂数据
 * @param current 当前分页
 */
async function queryFactory(current: number = 1, send_data?: QuerySendData, limit?: any) {

  const _send_data = {
    ...send_data,
    limit,
    // limit:2,
    current,
  };
  clean(_send_data);
  const url = getUrl(API.list, _send_data);
  const res = await axios.get(url);
  if (res.status !== 200) {
    //message.error((res as any).message);
  }
  const ware = formatWare(res.data);
  return ware;
}

/**
 * 新增工厂
 * @param send_data
 */
async function addFactory(send_data?: AddSendData) {
  clean(send_data);
  return await axios.post(API.list, send_data);
}

/**
 * 编辑工厂
 * @param id 工厂 id
 * @param send_data
 */
async function updateFactory(send_data: AddSendData, id: number) {
  clean(send_data);
  return await axios.put(`${API.list}/${id}`, send_data);
}

/**
 * 删除工厂
 * @param id 工厂 id
 * @param send_data
 */
async function delFactory(id: number) {
  return await axios.delete(`${API.list}/${id}`);
}

export default queryFactory;
export {
  queryFactory,
  addFactory,
  updateFactory,
  delFactory
};
