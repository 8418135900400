
import { Tagboard } from '@/view/factory/types/barcode'
import { defineStore } from 'pinia'
import { reactive, Ref, ref } from 'vue'
export const useTagboard = defineStore('tagboard', () => {
    const defBarCode = {
        id: undefined,
        pd_id: undefined,
        pd_code: undefined,
        pd_name: undefined,
        factory_name: undefined,
        factory_id: undefined,
        factory_code: undefined,
        batch_no: undefined,
        printed_number: undefined,
        ingredients: undefined,
        net_weight: undefined,
        gb_code: undefined,
        production_time: undefined,
        guarantee_period: undefined,
        storage_condition: undefined,
        manufacturer: undefined,
        address: undefined,
        contact_number: undefined,
        origin_place: undefined,
        code69: '',
        create_time: undefined,
        update_time: undefined,
        name: undefined,

    }
    const tagboard = ref<Partial<Tagboard>>(defBarCode);
    const setTagboard = (val: Tagboard | undefined) => {
        if (!val) {
            tagboard.value = defBarCode
            return
        }
        tagboard.value = val
    }
    return { tagboard, setTagboard }
})
