import { request } from '@/util/request';
import { message } from 'ant-design-vue';
import { CreactBarCode } from '../types/barcode';
import { Tagboard } from '../types/barcode';
const getUrl = require('bmh/url-with-param');

export async function getBarcodeList(name: string, current: number) {
    const url = `/boss/pd/label?name=${name}&current=${current}`
    // getUrl('', {name,current})
    try {
        const res = await request.get(url)
        return res.data
    } catch {
        return false
    }

}
export async function getLabel(id: string | number) {
    const url = `/boss/pd/label/${id}`
    try {
        const res = await request.get(url)
        return res.data
    } catch {
        return false
    }

}
export async function createLabel(barcode: Partial<CreactBarCode>) {
    const url = `/boss/pd/label`
    try {
        const res = await request.post(url, { ...barcode })
        if (res.status == 200) {
            message.success('创建成功')
        }
        else {
            message.success('创建失败，请检查参数')
        }
        return res.data
    } catch {
        return false
    }
}
export async function updataLabel(barcode: Partial<Tagboard>) {
    const url = `/boss/pd/label/${barcode?.id}`
    try {
        const res = await request.put(url, { ...barcode })
        if (res.status == 200) {
            message.success('修改成功')
        }
        else {
            message.success('创建失败，请检查参数')
        }
        return res.data
    } catch {
        return false
    }
}

export async function getBarcodeBatch(id: string, batch_no: string) {
    const url = `/boss/pd/label/batch/${id}?batch_no=${batch_no}`
    try {
        const res = await request.get(url)

        return res.data
    } catch {
        return false
    }
}
export async function printCallback(label_id: number | undefined, batch_no: string, number: number) {
    const url = `/boss/pd/label/print`
    try {
        const res = await request.post(url, {
            label_id,
            batch_no,
            number: Number(number)
        })
        if (res.status == 200) {
            message.success('打印数量以累计')
        }
        else {
            message.success('打印失败')
        }
        return res.data
    } catch {
        return false
    }
}
export async function getBoxBatch(batch_no: string) {
    const url = `/boss/box/label/batch/0?batch_no=${batch_no}`
    try {
        const res = await request.get(url)

        return res.data
    } catch {
        return false
    }
}
export async function boxPrintCallback(batch_no: number, number: number) {
    const url = `/boss/box/label/print`
    try {
        const res = await request.post(url, {
            batch_no,
            number: Number(number)
        })
        if (res.status == 200) {
            message.success('打印数量以累计')
        }
        else {
            message.success('打印失败')
        }
        return res.data
    } catch {
        return false
    }
}
// export async function name(id:number) {
//     const url = `boss/pd/label/batch/0`
// }